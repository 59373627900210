.team {

	.team-item-role {
		color: get-color(primary, 2);
	}
}

@include media( '>1005px' ) { // 1005px may need to e adjusted if testimonial items width or outer padding change
	.team {

		.tiles-wrap {
			padding-bottom: 96px;
		}

		.tiles-item {
			position: relative;

			&:nth-child(3n+2) {
				top: 48px;
			}

			&:nth-child(3n+3) {
				top: 96px;
			}
		}
	}
}

.split-wrap {

    .split-item {

        .split-item-image {
            
            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}